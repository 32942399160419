import axios from "axios";

let authApi = axios.create({
  baseURL: "https://rzehziqrmb.execute-api.us-west-2.amazonaws.com/prod/",
  headers: {
    "Content-Type": "application/json",
  },
});

export default {

  fetchCustnum(value){
    return authApi.post("axomQrVerify", value);
  }
}
