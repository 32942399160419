import React, { useState } from 'react';
import './LoginPage.css'; // Import your CSS file for styling
import authApi from './authApi';
import Loader from './loader/loader'; // Correct import path

const LoginPage = () => {
    const [loading, setLoading] = useState(false); // Initially set to false
    const [vcNumber, setVcNumber] = useState('');
    // const [password, setPassword] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true); // Set loading to true when starting the fetch

        // Handle login logic here
        console.log('vcNumber:', vcNumber);
        let data = {
            VC_NUMBER: vcNumber
        };

        try {
            const res = await authApi.fetchCustnum(data);
            if (res.status === 200) {
                var custnumLength = res.data.customer_number.length;
                console.log(res.data);
                console.log(res.data.customer_number);
                console.log(res.data.customer_number.length);

                if (custnumLength === 1) {
                    window.location.href = 'https://qr.mobiezy.in/?custNumber=' + res.data.customer_number[0].codeme;
                } else if(custnumLength > 1) {
                    alert("VC Number Matched with multiple customer. Contact your Cable Operator.");
                }
                else{
                    alert("This service is not available with your local cable operator");
                }
            }
        } catch (error) {
            console.log("Failed to fetch transactions", error);
            alert("An error occurred. Please try again.");
        } finally {
            setLoading(false); // Ensure loading is set to false after operation
        }
    };

    return (
        <div className="container">
            <div className="bottom-right">
                {loading ? (
                    <Loader />
                ) : (
                    <>
                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <input
                                    type="text"
                                    id="vcNumber"
                                    placeholder="Enter VC Number" // Use placeholder instead of hint
                                    value={vcNumber}
                                    onChange={(e) => setVcNumber(e.target.value)}
                                    required
                                />
                            </div>
                            {/* <label htmlFor="password">OR:</label>
                            <div className="form-group">
                                <input
                                    type="password"
                                    id="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                />
                            </div> */}
                            <button type="submit">SUBMIT </button>
                        </form>
                    </>
                )}
            </div>
        </div>
    );
};

export default LoginPage;