import './App.css';
import React from 'react';
import LoginPage from './LoginPage';
function App() {
  return (
      <div className="App">
          <LoginPage />
      </div>
  );
}

export default App;
