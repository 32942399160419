import React from 'react';
import './Loader.css'; 


function Loader() {
    return (
      <div className="loader">
        <div className="spinner"></div>
      </div>
    );
  }
  export default Loader;
  

  //
  /* src/LoginPage.css */
// .login-container {
//   max-width: 400px;
//   margin: auto;
//   padding: 1em;
//   border: 1px solid #ccc;
//   border-radius: 8px;
//   background: #f9f9f9;
// }

// h2 {
//   text-align: center;
//   margin-bottom: 1em;
//   color: black;
// }

// .form-group {
//   margin-bottom: 1em;
// }

// label {
//   margin-bottom: .5em;
//   color: #333333;
//   display: block;
// }

// input {
//   border: 1px solid #CCCCCC;
//   padding: 0.5em; /* Space inside the input */
//   font-size: 1em; /* Font size */
//   width: calc(90% - 1em); /* Adjust width to fit within the container, considering padding */
//   border-radius: 20px; /* Rounded corners */
//   box-sizing: border-box; /* Includes padding and border in the input's total width */
// }
// button {
//   background-color: rgba(195, 27, 27, 0.808);
//   color: white;
//   border: none;
//   padding: 0.7em;
//   font-size: 1em;
//   width: 50%;
//   border-radius: 20px;
//   cursor: pointer;
// }

// button:hover {
//   background-color: #0056b3;
// }

// .container {
//   position: relative; /* Establishes a positioning context for the child */
//   height: 100vh; /* Full height of the viewport for demonstration */
//   border: 1px solid #ccc;
//   background: #f9f9f9; /* Fallback color */
//   background-image: url('/assets/images/axombanner.png');
//   background-size: cover, contain; /* Different size settings for each image */
//   background-position: center, top right; /* Different positions for each image */
//   background-repeat: repeat, repeat; /* Repeat settings for each image */
// }


// .bottom-right {
//   position: absolute; /* Allows for precise positioning within the container */
//   bottom: 55%; /* Moves the element up from the bottom edge of the container */
//   right: -30px; /* Aligns to the right of the container with a 10px gap */
//   background: rgba(252, 178, 59, 0); /*rgb(195, 27, 27);*/
//   color: rgb(255, 255, 255); /* Ensures readability */
//   padding: 10px; /* Padding inside the container */
//   border-radius: 8px; /* Optional: rounded corners */
//   width: 400px; /* Adjust width as needed */
//   height: auto; /* Adjust height to fit content */
//   box-sizing: border-box; /* Includes padding and border in the element's total width and height */
//   /* border: 3px solid rgb(0, 0, 0); */
//   display: flex; /* Flexbox for better layout control */
//   flex-direction: column; /* Stack children vertically */
//   align-items: center; 
// }
// .bottom-right-image {
//   width: 100px; /* Adjust width as needed */
//   height: auto; /* Maintain aspect ratio */
//   margin-bottom: 10px; /* Space between image and label */
// }



